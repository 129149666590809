// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h8OzSRV7{align-items:center;display:flex;justify-content:center;margin:0 0 24px}@media(min-width:667.98px){.h8OzSRV7{justify-content:center;margin:0}}@media(min-width:947.98px){.h8OzSRV7{justify-content:flex-start}}.i59tvYpa{max-width:80px;width:33vw}@media(min-width:667.98px){.i59tvYpa{max-width:90px}}.i59tvYpa:nth-of-type(2){margin:0 32px}@media(min-width:1229.98px){.i59tvYpa:nth-of-type(2){margin:0 72px}}.bfv5GmGg{opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"logos": "h8OzSRV7",
	"logo": "i59tvYpa",
	"logoRuvents": "bfv5GmGg"
};
module.exports = ___CSS_LOADER_EXPORT___;
