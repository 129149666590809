// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h4AKezuD{display:none}@media(min-width:947.98px){.h4AKezuD{align-items:center;display:flex;justify-content:flex-end;margin:0 auto;padding:0 0 var(--spacing-s)}}.O04\\+lN4a{color:var(--color-text);display:flex;padding:var(--spacing-s)}._9wrFPJh1{height:1.5rem;width:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"socials": "h4AKezuD",
	"socialsLink": "O04+lN4a",
	"socialsIcon": "_9wrFPJh1"
};
module.exports = ___CSS_LOADER_EXPORT___;
