// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qsyt0\\+06{margin:0;position:relative}.Qsyt0\\+06:before{background-color:#212121;bottom:0;content:\"\";left:-16px;position:absolute;right:-16px;top:0}@media(min-width:947.98px){.Qsyt0\\+06:before{left:-50vw;right:-50vw}}.NpLOTJSt{font-size:13px;padding:32px 0 0;position:relative;width:100%}@media(min-width:947.98px){.NpLOTJSt{display:flex;flex-wrap:wrap;padding:90px 0 34px}}.t1I67rzI{padding:0 0 24px;text-align:center;width:100%}@media(min-width:947.98px){.t1I67rzI{align-items:center;display:flex;justify-content:space-between;padding:0 0 56px;text-align:left}.t1I67rzI:last-of-type{flex-direction:row-reverse}.l9\\+PXw-6{width:33%}}.l9\\+PXw-6 p{margin:0;padding:0 0 12px}.VpeHzIN9{color:var(--color-text);display:block;font-size:24px;font-weight:700;padding:0 0 16px}@media(min-width:947.98px){.VpeHzIN9{font-size:32px;padding:0 0 20px}}.NDZI6c1R{color:var(--gradient-from);display:block;font-size:20px;font-weight:700;padding:0 0 20px}@media(min-width:947.98px){.NDZI6c1R{font-size:26px;padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"footer": "Qsyt0+06",
	"wrapper": "NpLOTJSt",
	"row": "t1I67rzI",
	"cell": "l9+PXw-6",
	"phone": "VpeHzIN9",
	"email": "NDZI6c1R"
};
module.exports = ___CSS_LOADER_EXPORT___;
